// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/index/index-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-9c7b3f62]:root{--primary:#3d5a7b;--primary--hover:#007ad9;--primary--active:#0256bf;--error:#ff3730;--error-bg:rgba(243,69,76,.1);--error-border:#ffb9b9;--danger:#ea676d;--base-box-shadow:rgba(116,119,194,.1) 0 10px 45px 0;--base-form-element-height:60px;--base-form-element-border-raius:5px;--base-form-element-color:#3e3e3e;--base-form-element-placeholder-color:#010b23;--base-form-element-tip-color:#a9b1b8;--base-form-element-bg:#fafafa;--base-form-element-bg--focus:transparent;--base-form-element-border-color:#000;--base-button-height:var(--base-form-element-height);--base-button-border-raius:var(--base-form-element-border-raius);--base-input-color:#000;--base-input-placeholder-color:rgba(1,11,35,.2);--base-input-height:var(--form-element-height);--base-input-border-raius:var(--base-form-element-border-raius);--base-select-option-highlight-bg:rgba(62,62,62,.04);--base-label-color:var(--base-form-element-color);--skeleton-main-color:#b2cef0;--skeleton-highlight-color:hsla(0,0%,100%,.4);--saved-doc-title-color:#3e3e3e;--saved-doc-title-font-size:14px;--saved-doc-title-mb:8px;--saved-doc-bg:#fff;--saved-doc-wrapper-gap:8px;--saved-doc-wrapper-padding:8px 8px 4px 0;--saved-doc-main-color:#3d5a7b;--saved-doc-border-rad:8px;--saved-doc-button-bg:#d7e5f5;--floating-background:#3d5a7b;--floating-arrow-color:#3d5a7b;--floating-border-color:#3d5a7b;--floating-color-mark:#97d4fc;--floating-color:#fff}@media screen and (min-width:992px){.landing-search[data-v-9c7b3f62] .search-section{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-background-size:cover;background-size:cover;margin-top:-75px;padding-top:107px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
