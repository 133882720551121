
import { Component, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import Banner from '~/components/Banner.vue'
import Articles from '~/components/index/Articles.vue'
import Info from '~/components/index/Info.vue'
import NewBanner from '~/components/NewBanner.vue'
import Services from '~/components/index/Services.vue'
import FsspBlock from '~/components/index/FsspBlock.vue'
import Questions from '~/components/index/Questions.vue'
import HowItWorks from '~/components/index/HowItWorks.vue'
import InfoAccordion from '~/components/index/InfoAccordion.vue'
import InfoBlock from '~/components/index/InfoBlock.vue'
import SearchFormLanding from '~/components/SearchFormLanding.vue'
import FnsIndexWidgets from '~/components/index/FnsIndexWidgets.vue'

@Component({
  components: {
    FnsIndexWidgets,
    SearchFormLanding,
    Articles,
    Info,
    Banner,
    LazyHydrate,
    BookMarkPopup: () => import('~/components/popup/BookMarkPopup.vue'),
    NewBanner,
    Services,
    FsspBlock,
    Questions,
    HowItWorks,
    InfoAccordion,
    InfoBlock
  },
  middleware: 'index-static-middleware'
})
export default class extends Vue {
  popupComponent: any = () => import('~/components/Popup.vue')

  head () {
    return {
      link: [
        { rel: 'prefetch', as: 'image', href: require('~/assets/img/popup/ic_outline-star-outline.svg') }
      ]
    }
  }

  asyncData (context: any) {
    return context.store.dispatch('news/setList')
  }

  mounted () {
    if (this.$route.query.email) {
      this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }
    this.$axios.get(process.env.API_ENDPOINT + 'user/event?event_action=main', {
      params: {
        ci: this.$route.query.ci,
        gclid: this.$route.query.gclid,
        lead_source_id: this.$route.query.lead_source_id
      }
    })
  }
}
